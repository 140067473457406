<template>
  <v-card class="mx-auto">
    <v-card-title class="headline-box-headline primary white--text">notice</v-card-title>
    <v-card-text class="mt-5">
      <div v-html="result['string']"></div>
    </v-card-text>
    <v-card-actions>
      <primaryButton type="button" @click="closedialog()" label="OK" color="primary"></primaryButton>

      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  components: { primaryButton },
  props: ["result"],
  methods: {
    closedialog() {
      this.$store.commit("closeDialog", this.result.dialogRef);
    },
  },
};
</script>